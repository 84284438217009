import { breakpoints } from '$theme';
import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';
import { UmbracoTypes } from '~/lib/data-contract';
import { M10HeroProps } from '../M10Hero';
import { StyledAlignContent } from '~/templates/blocks/shared/AlignContent/styled';
import { Headline } from '~/templates/blocks/shared/Headline';

type StyledM10Props = Pick<M10HeroProps, 'backgroundColor' | 'maxHeight' | 'mobileContentPosition'>;
type StyledM10ContentProps = StyledM10Props & {
    hasBrandImage: boolean;
};

export const StyledMediaWrapper = styled.div<StyledM10Props>(
    {
        position: 'relative',
        zIndex: 1,
        aspectRatio: '0.8',
        width: '100%',
        maxHeight: UmbracoTypes.IM10HeroModuleMaxHeight.Large,

        [breakpoints.sm]: {
            aspectRatio: '2',
        },
        [breakpoints.md]: {
            width: '100%',
            aspectRatio: 'initial',
        },
    },

    ifProp(
        { maxHeight: UmbracoTypes.IM10HeroModuleMaxHeight.Small },
        {
            maxHeight: UmbracoTypes.IM10HeroModuleMaxHeight.Small,
            [breakpoints.md]: { maxHeight: UmbracoTypes.IM10HeroModuleMaxHeight.SmallDesktop },
        },
    ),
);

export const StyledM10 = styled.div<StyledM10Props>(
    ({ backgroundColor }) => ({
        position: 'relative',
        width: '100%',
        display: 'grid',
        margin: '0 auto',
        backgroundColor,
        overflow: 'hidden',
        maxHeight: UmbracoTypes.IM10HeroModuleMaxHeight.Large,

        [breakpoints.md]: {
            gridTemplateColumns: '50% 1fr',
            aspectRatio: '16/9',
        },
        [breakpoints.lg]: {
            gridTemplateColumns: '33% 1fr',
        },
    }),

    ifProp(
        { maxHeight: UmbracoTypes.IM10HeroModuleMaxHeight.Small },
        {
            [breakpoints.md]: { maxHeight: UmbracoTypes.IM10HeroModuleMaxHeight.SmallDesktop },
        },
    ),

    ifNotProp('backgroundColor', () => ({
        [`${StyledMediaWrapper}`]: {
            [breakpoints.md]: {
                overflow: 'hidden',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
            },
        },
    })),

    ifProp({ mobileContentPosition: 'beneath' }, ({ mobileContentPosition, maxHeight }) => ({
        maxHeight: 'none',

        [`${StyledMediaWrapper}`]: {
            gridColumn: '1/4',
            gridRow: mobileContentPosition === 'above' ? '2/3' : '1/2',

            [breakpoints.md]: {
                gridColumn: 'unset',
                gridRow: 'unset',
            },
        },
        [`${StyledM10Content}`]: {
            gridRow: mobileContentPosition === 'above' ? '1/2' : '2/3',

            [breakpoints.md]: {
                gridColumn: 'unset',
                gridRow: 'unset',
            },
        },

        [breakpoints.md]: {
            maxHeight:
                maxHeight === UmbracoTypes.IM10HeroModuleMaxHeight.Small
                    ? UmbracoTypes.IM10HeroModuleMaxHeight.SmallDesktop
                    : UmbracoTypes.IM10HeroModuleMaxHeight.Large,
        },
    })),
);

export const StyledM10Content = styled.div<StyledM10ContentProps>(
    ({ theme, backgroundColor }) => ({
        display: 'grid',
        position: 'relative',
        zIndex: 2,
        [`${StyledAlignContent}`]: {
            maxWidth: 350,
            boxSizing: 'content-box',
            backgroundColor,
            padding: `${theme.space[6]} ${theme.space[4]} ${theme.space[6]}`,
            [breakpoints.xs]: {
                paddingTop: backgroundColor ? theme.space['7'] : theme.space['6'],
                paddingBottom: backgroundColor ? theme.space['7'] : theme.space['6'],
            },
            [breakpoints.md]: {
                padding: theme.space['6'],
            },
        },
    }),

    ifProp('hasBrandImage', () => ({
        paddingTop: 100,
        [breakpoints.md]: {
            paddingBottom: 100,
        },
    })),

    ifProp(
        {
            hasBrandImage: true,
            maxHeight: UmbracoTypes.IM10HeroModuleMaxHeight.Small,
        },
        () => ({
            paddingTop: 70,

            [breakpoints.md]: {
                paddingBottom: 30,
            },
        }),
    ),

    ifProp(
        {
            maxHeight: UmbracoTypes.IM10HeroModuleMaxHeight.Small,
        },
        () => ({
            [`${StyledAlignContent}`]: {
                maxWidth: 440,
                paddingTop: 35,
            },
        }),
    ),
);

export const StyledHeadline = styled(Headline)({
    color: 'inherit',
});

export const StyledBrandImage = styled.div<StyledM10Props>(
    () => ({
        position: 'absolute',
        width: 100,
        zIndex: 100,
    }),

    ifProp(
        { maxHeight: UmbracoTypes.IM10HeroModuleMaxHeight.Small },
        ({ theme }) => ({
            height: '35px',
            width: 100,
            top: theme.space['4'],

            [breakpoints.sm]: {
                top: theme.space['5'],
                left: theme.space['6'],
            },
        }),
        ({ theme }) => ({
            height: 75,
            top: theme.space['5'],
            left: theme.space['2'],

            [breakpoints.md]: {
                left: theme.space['5'],
            },
            [breakpoints.lg]: {
                left: theme.space['6'],
                height: theme.space['12'],
            },
        }),
    ),
);
