import { getImageProps } from 'next/image';
import { useMedia } from 'react-use';
import { UmbracoTypes } from '~/lib/data-contract';
import { Button } from '~/shared/components/Button';
import { UmbracoImage } from '~/shared/components/Image';
import { umbracoLoader } from '~/shared/components/Image/loaders/umbracoLoader';
import { Spacer } from '~/shared/components/Spacer';
import { Text } from '~/shared/components/Text';
import { Video } from '~/shared/components/Video';
import { AlignContent } from '~/templates/blocks/shared/AlignContent';
import { breaks, queries, theme } from '~/theme';
import { useModule } from '../../../hooks/useModule';
import { useModuleTheme } from '../../../hooks/useModuleTheme';
import getFocalPoint from '../../../utils/getFocalPoint';
import {
    StyledBrandImage,
    StyledHeadline,
    StyledM10,
    StyledM10Content,
    StyledMediaWrapper,
} from './styled';

type Props = UmbracoTypes.IM10HeroModule;

export default function M10HeroContent({
    image,
    imageMobile,
    video,
    headline,
    subHeadline,
    callToAction,
    callToActionTypeFill,
    brandLogo,
    backgroundColor: initialBackgroundColor,
    backgroundColorHEX: initialBackgroundColorHex,
    maxHeight,
    mobileContentPosition,
    contentPosition,
}: Props) {
    const { index } = useModule();
    const { textColor, backgroundColor, buttonColor, buttonVariant } = useModuleTheme({
        backgroundColor: initialBackgroundColorHex || initialBackgroundColor,
        buttonVariant: callToActionTypeFill ? 'fill' : 'stroke',
    });

    const isTabletUp = useMedia(queries.md, false);

    const {
        props: { srcSet: desktop, ...desktopProps },
    } = getImageProps({
        loader: umbracoLoader,
        src: image?.src ?? '',
        alt: 'Hero Desktop',
        fill: true,
        sizes: `${breaks.pageMax}px`,
        loading: index < 2 ? 'eager' : 'lazy',
        style: {
            objectFit: 'cover',
            objectPosition: getFocalPoint(image),
        },
    });

    const {
        props: { srcSet: mobile, ...mobileProps },
    } = getImageProps({
        loader: umbracoLoader,
        src: imageMobile?.src ?? '',
        alt: 'Hero Mobile',
        fill: true,
        loading: index < 2 ? 'eager' : 'lazy',
        sizes: '100vw',
        style: {
            objectFit: 'cover',
            objectPosition: getFocalPoint(imageMobile),
        },
    });

    const mediaType = video ? 'video' : 'image';

    const enumMaxHeight =
        UmbracoTypes.IM10HeroModuleMaxHeight[
            maxHeight as unknown as keyof typeof UmbracoTypes.IM10HeroModuleMaxHeight
        ];

    return (
        <StyledM10
            backgroundColor={backgroundColor}
            maxHeight={enumMaxHeight}
            mobileContentPosition={mobileContentPosition}
        >
            <StyledM10Content
                backgroundColor={backgroundColor}
                hasBrandImage={!!brandLogo?.src}
                maxHeight={enumMaxHeight}
                mobileContentPosition={mobileContentPosition}
            >
                {brandLogo?.src ? (
                    <StyledBrandImage maxHeight={enumMaxHeight}>
                        <UmbracoImage
                            src={brandLogo.src}
                            alt={brandLogo.alt ?? ''}
                            fill
                            style={{ objectFit: 'contain' }}
                            focalPoint={brandLogo.focalPoint}
                            sizes={theme.space[12]}
                            priority={index < 2}
                        />
                    </StyledBrandImage>
                ) : null}

                <AlignContent alignment="left" position={contentPosition} textColor={textColor}>
                    {headline ? (
                        <StyledHeadline variant={'display1'}>{headline}</StyledHeadline>
                    ) : null}
                    {subHeadline ? (
                        <>
                            <Spacer size="4" customFrom={{ '451px': '5' }} />
                            <Text variant="display6" as="p" style={{ color: textColor }}>
                                {subHeadline}
                            </Text>
                        </>
                    ) : null}
                    {callToAction ? (
                        <>
                            <Spacer size="4" customFrom={{ '451px': '5', '1000px': '6' }} />

                            <Button variant={buttonVariant} color={buttonColor}>
                                {callToAction.text}
                            </Button>
                        </>
                    ) : null}
                </AlignContent>
            </StyledM10Content>

            {image || video ? (
                <StyledMediaWrapper maxHeight={enumMaxHeight}>
                    {mediaType === 'image' ? (
                        <picture>
                            <source
                                media={imageMobile?.src ? `${queries.md}` : ''}
                                srcSet={desktop}
                            />
                            {imageMobile?.src && <source srcSet={mobile} />}
                            <img
                                {...(isTabletUp ? desktopProps : mobileProps)}
                                alt={imageMobile?.alt}
                            />
                        </picture>
                    ) : (
                        <Video
                            muted={true}
                            controls={false}
                            src={video?.src ?? ''}
                            loop={video?.loop ?? false}
                            playing={video?.autoPlay ?? false}
                            posterSrc={image?.src}
                            cover={true}
                        />
                    )}
                </StyledMediaWrapper>
            ) : null}
        </StyledM10>
    );
}
