import NextLink from 'next/link';
import { UmbracoTypes } from '~/lib/data-contract';
import { Link } from '~/shared/components/Link';
import { default as M10HeroContent } from './components/M10HeroContent';
import { ModuleContainer } from '../../shared/ModuleContainer';

export type M10HeroProps = UmbracoTypes.IM10HeroModule;

export default function M10Hero({ spacingBottom, callToAction, ...rest }: M10HeroProps) {
    return (
        <ModuleContainer hasGutter spacingBottom={spacingBottom}>
            {callToAction ? (
                <NextLink
                    href={callToAction.url}
                    target={callToAction.target}
                    passHref
                    legacyBehavior
                >
                    <Link target={callToAction.target}>
                        <M10HeroContent callToAction={callToAction} {...rest} />
                    </Link>
                </NextLink>
            ) : (
                <M10HeroContent callToAction={callToAction} {...rest} />
            )}
        </ModuleContainer>
    );
}
